import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import Main from './Main';

const App = () => {
	return (
		<Router>
			<div>
				<Header className='terroir-from-space-header'/>
				<Main className='terroir-from-space-main'/>
			</div>
		</Router>
	);
}

export default App;
