import React from 'react';

const Presentation = () => {
	return (
		<div className='terroir-from-space terroir-from-space-presentation'>
			<div className='container'>
				<div className='jumbotron'>
					<div className='container'>
						<h1 className='display-5'>Presentation</h1>
						<p className='lead'>Terroir from Space offers services for detecting unexploited planting sites eligible to host new vineyards. By exploiting Earth Observation (EO) data coming from satellites, several parameters associated with the soil and the climate are obtained for different locations. By ingesting this information within our properly trained AI algorithm, we can detect and classify new planting sites, with long-term resilience to climate change. </p>
						<p className='lead'>Moreover, we offer a monitoring service of the status of existing vineyards, assessing site exploitation risks and providing advice on the best agronomic practices to be implemented.</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Presentation;