import React from 'react';
import VideoPlayer from './VideoPlayer';

const Vision = () => {
	return (
		<div className='terroir-from-space terroir-from-space-vision'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-1 col-lg-2'></div>
					<div className='col-md-10 col-lg-8'>
						<div className='video-container'>
							<VideoPlayer />
						</div>
					</div>
					<div className='col-md-1 col-lg-2'></div>
				</div>
			</div>
		</div>
	);
}

export default Vision;