import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Homepage from '../components/Homepage';
import Presentation from '../components/Presentation';
import Vision from '../components/Vision';
import Team from '../components/Team';
import Contacts from '../components/Contacts';

const Main = (props) => {
	return (
		<div className='container'>
			<TransitionGroup className='transition-group'>
				<CSSTransition
					key={props.location.key}
					timeout={{ enter: 300, exit: 300 }}
					classNames={'fade'}
				>
					<section className='route-section'>
						<Switch location={props.location}>
							<Route path='/' exact component={Homepage}/>
							<Route path='/site/' exact component={Homepage}/>
							<Route path='/presentation' exact component={Presentation}/>
							<Route path='/vision' exact component={Vision}/>
							<Route path='/team' exact component={Team}/>
							<Route path='/contacts' exact component={Contacts}/>
						</Switch>
					</section>
				</CSSTransition>
			</TransitionGroup>
		</div>
	);
}

export default withRouter(Main);