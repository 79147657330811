import React from 'react';
import logo from '../images/logo/terroir-from-space-logo.png';

const Homepage = () => {
	return (
		<div className='terroir-from-space terroir-from-space-home'>
			<div className='container'>
				<div className='jumbotron'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-2'>
								<img src={logo} alt='Warbler logo' width='100' height='100'/>
							</div>
							<div className='col-md-10 title-container'>
								<h1 className='display-5' id='title'>TERROIR FROM SPACE</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Homepage;