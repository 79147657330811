// https://react-bootstrap.github.io/

import React from 'react';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../images/logo/terroir-from-space-logo.png'

class Header extends React.Component {

	constructor(props) {
		super(props);
		this.navbarCollapseRef = React.createRef();
	}
	
	setNavbarClass = () => {
		this.navbarCollapseRef.current.classList.toggle('show');
	}
	
	render() {
		return (
			<Navbar collapseOnSelect bg='transparent' expand={false} fixed='top'>
				<Navbar.Brand className='py-0'>
					<NavLink to='/' className='navbar-brand py-0' onClick={this.setNavbarClass}>
						<img src={logo} alt='Warbler logo' width='50' height='50'/>
					</NavLink>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' className='pr-0 pr-sm-3' />
				<Navbar.Collapse id='responsive-navbar-nav' ref={this.navbarCollapseRef}>
					<Nav className='pr-0 pr-sm-3'>
						<NavLink className='nav-link' to='/vision' exact activeClassName='active'>Our vision</NavLink>
						<NavLink className='nav-link' to='/presentation' exact activeClassName='active'>Presentation</NavLink>
						<NavLink className='nav-link' to='/team' exact activeClassName='active'>Who we are</NavLink>
						<NavLink className='nav-link' to='/contacts' exact activeClassName='active'>Contacts</NavLink>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		)
	}
}

export default Header;