import React from 'react';
import logo1 from '../images/supporters/parsec.png';
import logo2 from '../images/supporters/startup3.png';
import logo3 from '../images/supporters/agrifood.png';
import logo4 from '../images/supporters/porto_protocol2.jpg';
import logo5 from '../images/supporters/eit_food.jpg';

const Team = () => {
	return (
		<div className='terroir-from-space terroir-from-space-team'>
			<div className='container-fluid'>
				<div className='jumbotron py-3'>
					<div className='container mx-5 px-5'>
						<h1 className='display-5'>Who we are</h1>
						<p className='lead px-5'>We are a small group of innovators who believe it is possible to adapt and mitigate the effects of climate change on agriculture. Alessandro, Aerospace Engineer from Politecnico di Milano and passionate about wine, is the founder of Terroir from Space.</p>
						<p className='lead px-5'>Our team is currently composed by 2 members and expects to grow in number soon!</p>
					</div>
				</div>
				<div className='container-fluid p-0 mx-0'>
					<h1 className='display-5 mb-1 mb-md-2' id='trusted-by'>We are supported by</h1>
					<div className='row d-flex justify-content-around mx-0 mt-4'>
						<div className='col-2'>
							<a href='https://parsec-accelerator.eu/' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo1} alt='Warbler logo' height='55'/>
							</a>
						</div>
						<div className='col-2'>
							<a href='https://startup3.eu/' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo2} alt='Warbler logo' height='55'/>
							</a>
						</div>
						<div className='col-2'>
							<a href='https://itc-cluster.com/dih-agrifood/' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo3} alt='Warbler logo' height='55'/>
							</a>
						</div>
						<div className='col-2'>
							<a href='https://www.portoprotocol.com/founding-members/terroir-from-space-2/' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo4} alt='Warbler logo' height='55'/>
							</a>
						</div>
						<div className='col-2'>
							<a href='https://www.eitfood.eu/' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo5} alt='Warbler logo' height='55'/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Team;