import React from 'react';
import logo1 from '../images/contacts/email.png';
import logo2 from '../images/contacts/youtube.png';
import logo3 from '../images/contacts/linkedin.png';

const Contacts = () => {
	return (
		<div className='terroir-from-space terroir-from-space-contacts'>
			<div className='container-fluid'>
				<div className='jumbotron pb-2'>
					<div className='container'>
						<h1 className='display-5'>Contact us</h1>
						<p className='lead'>Feel free to contact us to receive more information about our project and to explore chances of collaborations. Ciao!</p>
					</div>
				</div>
				<div className='container p-0 mb-1 mb-md-2'>
					<div className='row d-flex justify-content-around align-items-center'>
						<div className='col-3'></div>
						<div className='col-2'>
							<a href='mailto:info@terroirfromspace.com' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo1} alt='Warbler logo' height='50'/>
							</a>
						</div>
						<div className='col-2'>
							<a href='https://www.youtube.com/channel/UCVaXLViOdUvlQuoLX_rweLw' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo2} alt='Warbler logo' height='60'/>
							</a>
						</div>
						<div className='col-2'>
							<a href='https://www.linkedin.com/company/terroir-from-space/' target='_blank' rel='noopener noreferrer'>
								<img className='supporter-logo' src={logo3} alt='Warbler logo' height='40'/>
							</a>
						</div>
						<div className='col-3'></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contacts;