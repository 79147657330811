// https://video-react.js.org/components/player/
// https://www.npmjs.com/package/react-player

import React from 'react';
import ReactPlayer from 'react-player/youtube';

const VideoPlayer = () => {
  return (
		<ReactPlayer 
			className='react-player'
			url='https://www.youtube.com/watch?v=Jqpgqj-JGLA'
			width='100%'
			height='100%'
			controls={true}
		/>
  );
};

export default VideoPlayer;